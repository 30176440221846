/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'vr-material-theme' as vr;

@include mat.core();

@import './reboot';

html {
  @include mat.core-theme(vr.$light-theme);
  @include mat.tooltip-theme(vr.$light-theme);
  @include mat.form-field-theme(vr.$light-theme);
  @include mat.datepicker-theme(vr.$light-theme);
  @include mat.select-theme(vr.$light-theme);
  @include mat.autocomplete-theme(vr.$light-theme);
  @include mat.checkbox-theme(vr.$light-theme);
  @include mat.button-theme(vr.$light-theme);
  @include mat.slide-toggle-theme(vr.$light-theme);
  @include mat.menu-theme(vr.$light-theme);
  @include mat.divider-theme(vr.$light-theme);
  @include mat.paginator-theme(vr.$light-theme);
  @include mat.progress-spinner-theme(vr.$light-theme);

  @include mat.form-field-density(-2);
  @include mat.table-density(-5);
  @include mat.paginator-density(-5);
}

.vr-theme {
  background: #f5f5f5;
  font-size: 14px;
  line-height: 1.5;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Ultralight-WebM.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-UltralightItalic-WebM.ttf")
    format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Thin-WebM.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-ThinItalic-WebM.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Light-WebM.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-LightItalic-WebM.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Regular-WebM.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-RegularItalic-WebM.ttf")
    format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Book-WebM.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-BookItalic-WebM.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Medium-WebM.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-MediumItalic-WebM.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-SemiBold-WebM.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-SemiBoldItalic-WebM.ttf")
    format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Bold-WebM.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-BoldItalic-WebM.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-Black-WebM.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "SuisseIntl";
  src: url("../assets/font/SuisseIntl-BlackItalic-WebM.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
